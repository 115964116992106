<template>
    <div>
        <p class="fs8 fwbold mb10">报名表单设置</p>
        <h4 class="cA3">已开始报名的活动无法编辑表单</h4>
        <br>
        <div class="d-flex justify-content-between">
            <div class="formType">
                <p class="fs8 fwbold">组件</p>
                <div class="formTypeList">
                    <div class="formTypeItem" v-for="(row, index) in formTypeData" :key="index"
                        @click="selectEnter(row.type, row.title)">
                        <div :class="'iconForm ' + row.icon"></div>
                        <div>{{ row.title }}</div>
                    </div>
                </div>
            </div>
            <div class="formContent pa10">
                <vuedraggable v-model="detailRequests" v-bind="{ animation: 200 }">
                    <transition-group>
                        <div class="formItem" :class="{ 'on': index == setIndex }" @click="setIndex = index"
                            v-for="(row, index) in detailRequests" :key="index">
                            <div v-if="row.arfType == 'SINGLE'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="childrenDetails" v-for="(child, key) in row.childrenDetails" :key="key">
                                    <el-radio :label="child.title">{{
                                        child.title }}</el-radio>
                                </div>
                            </div>
                            <div v-if="row.arfType == 'MULTIPLE'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="childrenDetails" v-for="(child, key) in row.childrenDetails" :key="key">
                                    <el-checkbox :label="child.title">{{
                                        child.title }}</el-checkbox>
                                </div>
                            </div>
                            <div v-if="row.arfType == 'DROP_DOWN'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <el-select class="pl10" v-model="formData.select" style="width:100%;"
                                    @change="formData.select = ''" placeholder="下拉选项">
                                    <el-option v-for="(child, key) in row.childrenDetails" :key="key" :label="child.title"
                                        :value="child.title">
                                    </el-option>
                                </el-select>
                            </div>
                            <div v-if="row.arfType == 'TEXTAREA'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <el-input class="pl10" type="textarea"
                                    :class="{ 'mb10': key < (row.childrenDetails.length - 1) }" placeholder="请输入文本" rows="3"
                                    disabled v-for="(child, key) in row.childrenDetails" :key="key" :label="child.title"
                                    :value="child.title"></el-input>
                            </div>
                            <div v-if="row.arfType == 'SCOURE'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="d-flex ml10" :class="{ 'mb10': key < (row.childrenDetails.length - 1) }"
                                    v-for="(child, key) in row.childrenDetails" :key="key">
                                    <div class="mr10">
                                        {{ child.title }}
                                    </div>
                                    <el-rate v-model="formData.rate" :disabled="true" :colors="colors"> </el-rate>
                                </div>
                            </div>
                            <div v-if="row.arfType == 'IMAGE'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="ml10">
                                    <el-upload :action="actionURL" list-type="picture-card" :disabled="true"
                                        :auto-upload="false">
                                        <i slot="default" class="el-icon-plus"></i>
                                        <div slot="file" slot-scope="{ file }">
                                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                                            <span class="el-upload-list__item-actions">
                                                <span v-if="!disabled" class="el-upload-list__item-preview">
                                                    <i class="hover_pointer el-icon-zoom-in"></i>
                                                </span>
                                                <span v-if="!disabled" class="el-upload-list__item-delete">
                                                    <i class="hover_pointer el-icon-download"></i>
                                                </span>
                                                <span v-if="!disabled" class="el-upload-list__item-delete">
                                                    <i class="hover_pointer el-icon-delete"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                </div>
                            </div>
                            <div v-if="row.arfType == 'GPS'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="ml10">
                                    <el-button type="info" icon="el-icon-location-outline" disabled
                                        v-for="(child, key) in row.childrenDetails" :key="key"
                                        size="small">点击获取位置</el-button>
                                </div>
                            </div>
                            <div v-if="row.arfType == 'USERINFO'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="ml10" :class="{ 'mb10': key < (row.childrenDetails.length - 1) }"
                                    v-for="(child, key) in row.childrenDetails" :key="key">
                                    <div class="mb10">{{ child.title }}</div>
                                    <el-input placeholder="请输入个人信息" disabled :label="child.content"
                                        :value="child.content"></el-input>
                                </div>
                            </div>
                            <div v-if="row.arfType == 'DATEPICKER'">
                                <div class="flex-a-b-c mb10">
                                    <p><span v-if="row.required" class="req">*</span>{{ row.title }}</p>
                                    <i @click="deleteForm(index)" class="deleteForm el-icon-circle-close"></i>
                                </div>
                                <div class="ml10">
                                    <el-date-picker v-model="child.content" type="daterange" style="width: 100%"
                                        v-for="(child, key) in row.childrenDetails" :key="key" range-separator="至"
                                        :disabled="true" start-placeholder="开始日期" end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                </vuedraggable>
            </div>
            <div class="formValue">
                <div v-if="detailRequests.length > 0">
                    <el-form v-if="detailRequests[setIndex].arfType == 'SINGLE'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10">单选项</p>
                        <el-form-item label="标题">
                            <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label="必填">
                            <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                            </el-switch>
                        </el-form-item>
                        <div class="ml10" v-for="(children, chIndex) in detailRequests[setIndex].childrenDetails"
                            :key="chIndex">
                            <el-form-item label="选项">
                                <div class="flex-c-c">
                                    <el-input type="text" class="mr10" placeholder="请输入选项" v-model="children.title">
                                    </el-input>
                                    <i class="hover_pointer el-icon-remove-outline hover_pointer"
                                        @click="deleteOption(detailRequests[setIndex].childrenDetails, chIndex)"></i>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="ml10">
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                @click="addOption(detailRequests[setIndex].childrenDetails, detailRequests[setIndex].arfType)">添加单选
                            </el-button>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].arfType == 'MULTIPLE'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10">多选项</p>
                        <el-form-item label="标题">
                            <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                style="width: 300px" maxlength="10">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="必填">
                            <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                            </el-switch>
                        </el-form-item>

                        <div class="ml10" v-for="(children, chIndex) in detailRequests[setIndex].childrenDetails"
                            :key="chIndex">
                            <el-form-item label="选项">
                                <div class="flex-c-c">
                                    <el-input type="text" class="mr10" placeholder="请输入选项" v-model="children.title"
                                        maxlength="20">
                                    </el-input>
                                    <i class="hover_pointer el-icon-remove-outline"
                                        @click="deleteOption(detailRequests[setIndex].childrenDetails, chIndex)"></i>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="ml10">
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                @click="addOption(detailRequests[setIndex].childrenDetails, detailRequests[setIndex].arfType)">添加多选</el-button>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].arfType == 'DROP_DOWN'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">下拉选项</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="20">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>

                        <div class="ml10" v-for="(children, chIndex) in detailRequests[setIndex].childrenDetails"
                            :key="chIndex">
                            <el-form-item label="选项">
                                <div class="flex-c-c">
                                    <i class="hover_pointer el-icon-caret-bottom"></i>
                                    <el-input type="text" style="width: 200px" class="mr10" placeholder="请输入选项"
                                        v-model="children.title" maxlength="20">
                                    </el-input>
                                    <i class="hover_pointer el-icon-remove-outline"
                                        @click="deleteOption(detailRequests[setIndex].childrenDetails, chIndex)"></i>
                                </div>
                            </el-form-item>
                        </div>

                        <div class="ml10">
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                @click="addOption(detailRequests[setIndex].childrenDetails, detailRequests[setIndex].arfType)">添加单个下拉</el-button>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].arfType == 'TEXTAREA'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">文本框</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="20">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                        <div class="ml10" v-for="(children, chIndex) in detailRequests[setIndex].childrenDetails"
                            :key="chIndex">
                            <el-form-item label="内容">
                                <div class="d-flex">
                                    <el-input type="textarea" class="mr10" :disabled="true" :rows="3" placeholder="请输入文本"
                                        v-model="children.title" style="width: 250px" maxlength="500" show-word-limit>
                                    </el-input>
                                    <i class="hover_pointer el-icon-remove-outline"
                                        @click="deleteOption(detailRequests[setIndex].childrenDetails, chIndex)"></i>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="ml10">
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                @click="addOption(detailRequests[setIndex].childrenDetails, detailRequests[setIndex].arfType)">添加单个文本</el-button>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].arfType == 'SCOURE'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">评分</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                        <div class="ml10">
                            <el-form-item label="内容">
                                <div v-for="(children, chIndex) in detailRequests[setIndex].childrenDetails" :key="chIndex"
                                    class="mb10">
                                    <div class="flex-c-c">
                                        <el-input type="text" placeholder="请输入文本" v-model="children.title" maxlength="10"
                                            style="width: 250px" show-word-limit>
                                        </el-input>
                                        <i class="hover_pointer el-icon-remove-outline ml10"
                                            @click="deleteOption(detailRequests[setIndex].childrenDetails, chIndex)"></i>
                                    </div>
                                    <div>
                                        <el-rate v-model="formData.rate" :disabled="true" :colors="colors"> </el-rate>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>

                        <div class="ml10">
                            <el-button type="primary" icon="el-icon-circle-plus-outline"
                                @click="addOption(detailRequests[setIndex].childrenDetails, detailRequests[setIndex].arfType)">添加单个评分</el-button>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].arfType == 'IMAGE'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">图片上传</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>

                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].arfType == 'GPS'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">地址定位</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="地址定位" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>

                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].arfType == 'USERINFO'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">个人信息</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                        <div class="ml10" v-for="(children, chIndex) in detailRequests[setIndex].childrenDetails"
                            :key="chIndex">
                            <el-form-item label="标题">
                                <div class="flex-c-c">
                                    <el-input type="text" style="width:200px" placeholder="请输入标题" v-model="children.title"
                                        maxlength="10" show-word-limit>
                                    </el-input>
                                    <i class="hover_pointer el-icon-remove-outline ml10"
                                        @click="deleteOption(detailRequests[setIndex].childrenDetails, chIndex)"></i>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="ml10">
                            <el-button icon="el-icon-circle-plus-outline" type="primary"
                                @click="addOption(detailRequests[setIndex].childrenDetails, detailRequests[setIndex].arfType)">添加单个标题内容</el-button>
                        </div>
                    </el-form>

                    <el-form v-else-if="detailRequests[setIndex].arfType == 'DATEPICKER'" class="commentForm pa10">
                        <p class="fs8 fwbold mb10 ml20">日期选择</p>
                        <div>
                            <el-form-item label="标题">
                                <el-input type="text" placeholder="请输入标题" v-model="detailRequests[setIndex].title"
                                    style="width: 300px" maxlength="10">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="必填">
                                <el-switch v-model="detailRequests[setIndex].required" active-text="开" inactive-text="关">
                                </el-switch>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vuedraggable from 'vuedraggable' //拖拽组件
export default {
    props: ['initFormData'],
    components: { vuedraggable },
    data() {
        return {
            colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
            formData: {
                select: "",
                rate: 3
            },
            actionURL: this.$store.state.ossUrl,
            setIndex: 0,
            detailRequests: [],
            formTypeData: [
                { type: "SINGLE", title: "单选项", icon: "iconFormradio" },
                { type: "MULTIPLE", title: "多选项", icon: "iconFormcheckbox" },
                { type: "DROP_DOWN", title: "下拉选项", icon: "iconFormxialaxuanxiang" },
                { type: "TEXTAREA", title: "文本框", icon: "iconFormwenbenkuang" },
                { type: "SCOURE", title: "评分", icon: "iconFormscore" },
                { type: "IMAGE", title: "图片上传", icon: "iconFormtupianshangchuan" },
                { type: "GPS", title: "地址定位", icon: "iconForma-dizhidingwei" },
                { type: "USERINFO", title: "个人信息", icon: "iconFormgerenxinxi_o" },
                { type: "DATEPICKER", title: "日期选择", icon: "iconFormriqixuanze" },
            ],
            radio: ""
        }
    },
    watch: {
        initFormData: {
            handler(arr) {
                this.detailRequests = arr ? arr : [];
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        deleteForm(index) {
            if (this.setIndex == this.detailRequests.length - 1 && this.setIndex != 0) {
                this.setIndex = this.detailRequests.length - 2;
            }
            this.detailRequests.splice(index, 1);
        },
        addOption(optionArray, type) {
            if (optionArray && optionArray.length < 6) {
                if (type == 'TEXTAREA') {
                    optionArray.push({ arfType: type, title: "", content: "" });
                } else if (type == 'SCOURE' || type == 'USERINFO') {
                    optionArray.push({ arfType: type, title: "标题" + (optionArray.length + 1), content: "" });
                } else {
                    optionArray.push({ arfType: type, title: "选项" + (optionArray.length + 1), content: "" });
                }
            } else {
                //最多添加6个
                this.$message.info("选项最多只能添加6个")
            }
        },
        deleteOption(optionArray, index) {
            optionArray.splice(index, 1);
        },
        selectEnter(type, title) {
            if (this.detailRequests.length >= 10) {
                this.$message({
                    type: "warning",
                    message: "最多可添加10个类型",
                });
                return;
            }
            if (type == 'TEXTAREA' || type == 'DATEPICKER' || type === 'GPS') {
                this.detailRequests.push({
                    arfType: type,
                    title: title,
                    content: "",
                    childrenDetails: [
                        { arfType: type, title: "", content: "" },
                    ],
                });
            } else if (type === 'SCOURE' || type == 'USERINFO') {
                this.detailRequests.push({
                    arfType: type,
                    title: title,
                    content: "",
                    childrenDetails: [
                        { arfType: type, title: "标题1", content: "" },
                    ],
                });
            } else if (type === 'IMAGE') {
                this.detailRequests.push({
                    arfType: type,
                    title: title,
                    content: "",
                    childrenDetails: [],
                });
            } else {
                this.detailRequests.push({
                    arfType: type,
                    title: title,
                    content: "",
                    childrenDetails: [
                        { arfType: type, title: "选项1", content: "" },
                        { arfType: type, title: "选项2", content: "" },
                        { arfType: type, title: "选项3", content: "" }
                    ],
                });
            }
            this.setIndex = this.detailRequests.length - 1
        },
    }
}
</script>

<style lang="scss" scoped>
.formType {
    border-radius: 5px;
    padding: 0 20px;
    overflow: hidden;
    width: 282.5px;
    height: 600px;
    background: #fff;
    border: 1px solid #dcdfe6;

    .fwbold {
        padding: 20px 10px;
    }

    .formTypeList {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        .formTypeItem {
            height: 80px;
            width: 80px;
            text-align: center;
            border: 1px solid #dcdfe6;
            cursor: pointer;

            .iconForm {
                font-size: 35px;
            }
        }
    }

    .formTypeItem:hover {
        background: #51CDCB;
        color: #fff;
    }
}

.formContent {
    height: 600px;
    overflow-y: scroll;
    border-radius: 5px;
    width: 400px;
    background: #fff;
    border: 1px solid #dcdfe6;

    .formItem {
        cursor: move;
        border: 1px dashed #dcdfe6;
        padding: 10px;
        margin-bottom: 20px;

        .childrenDetails {
            margin-left: 10px;
        }

        p {
            font-size: 15px;

            .req {
                color: red;
                margin-right: 5px;
            }

        }

        .deleteForm {
            color: #F56C6C;
            z-index: 2;
            cursor: pointer;
            font-size: 18px;
        }


        .commentForm {
            border: 1px solid #dcdfe6;
            padding-top: 10px;
            margin-top: 10px;
        }
    }

    .formItem.on {
        border: 1px solid #51CDCB;
    }

}

.formValue {
    border-radius: 5px;
    width: 350px;
    height: 600px;
    overflow-y: scroll;
    background: #fff;
    border: 1px solid #dcdfe6;
}
</style>